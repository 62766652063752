body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* http://skyentific.me/adding-fonts-to-create-react-app/#:~:text=Download%20the%20font%20from%20Google,create%2Dreact%2Dapp%20project.&text=Copy%20the%20fonts%20you%20want,into%20the%20'fonts'%20directory. */
* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

.main-height {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-title {
  font-size: 2.3rem !important;
  text-align: center !important;
}

.page-sub-title {
  font-size: 0.9rem !important;
  text-align: center !important;
}

.container-back {
  margin: 0rem 11%;
}

.container-back-content {
  font-family: Poppins, sans-serif;
  color: #ec9177;
  text-align: justify;
  background-color: #f7f7f7;
}

.container-back-content-full {
  font-family: Poppins, sans-serif;
  color: #ec9177;
  text-align: justify;
  background-color: #f7f7f7;
  height: 60vh;
}


.DayPickerInput {
  width: 100%;
  display: inline-block;
}

.form-control {
  border: 1px solid #ec9177;
}

.DayPickerInput>input {
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ec9177;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}