.vendor-or {
  padding-top: 1rem;
  text-align: center;
}

.firebaseui-title {
  background-color: white !important;
}

button.vendor-login-dont {
  background-color: rgb(215, 113, 85, 0.7) !important;
}

.googlebutton {
  background-color: #F7F7F7 !important;
}

.sendinvitation-message{
  margin-top: 1.3rem;
  padding: 1.2rem;
  border: solid 1px rgb(215, 113, 85, 0.7);
}