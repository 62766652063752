


.bold {
    font-weight: bold;
}
.about-link-color{
    color: #c06248;
}

.about-layout {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    margin: 0 1rem;
}

.about-text {
    flex: 1 1 100%;
    font-size: 1rem;
    text-align: justify;
}

.about-title {
    margin-top: 1rem;
}

.first-title {
    margin-left: 1rem;
}

.keep-in-line {
    display: inline-block;
    margin-right: 0.4rem;
}

.pics {
    display: flex;
    justify-content: center;
    flex: 1 1 100%;
    margin-top: 1rem
}

.pics img {
    width: 100%;
    height: auto;
}

.pic-right {
    vertical-align: middle;
}


.about-part-2-and-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 1rem;
}

@media screen and (min-width: 1025px) {
    .text-left {
        flex-direction: row-reverse;
    }

    .text-right {
        flex-direction: row;
    }

    .pic-right {
        margin-left: 1rem;
    }

    .pic-left {
        margin-right: 1rem;
    }

    .page-title-about {
        font-size: 1.5rem !important;
    }

}