/* Universal Styles */

html {
  font-size: 18px;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}


.navbar-custom {
  padding: 0 0 .2rem 0 !important; 
  margin-top: .9rem;
} 



.nav-link{
  color: #ec9177 !important;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
}


.mainContainer{
  height: 90%;
	display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  width:100%;
  justify-content: center;
  background-color: #fffbf8;
}

.insidePages {
  padding: 2rem;
}

.main-main-main{
  /* border-radius: 0.5rem; */
  width: 75%;
  background-color: rgb(244, 172, 147);
  margin: .6rem auto;
}


.main-all {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #ec9177;
}


/* ----------- going global */

h1 {
  padding: .4rem 0;
  border-top: 1px solid rgb(215, 113, 85, 0.5);
  font-family: Poppins, sans-serif;
  font-size: 1.8rem !important;
  color:rgb(215, 113, 85, 0.7);
  margin-bottom: 0 !important;
}


small.text-muted{
  color: #ec9177 !important;
}


/* .vendor-submit { */
.submit {
  text-align: center;
  margin: .4rem;
  align-items: flex-end;
}

/* .vendor-submit a { */
.submit a {
  border-radius: 4px;
  padding: .72rem 1.7rem;
  background-color: #ec9177;
  color: #ffffff !important;
  font-weight: 600;
  transition: background-color .5s;
}

/* .vendor-submit a:hover { */
.submit a:hover {
  cursor: pointer;
  background-color: #ec9177;
}