.confirm-account-main{
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    font-size: 2rem;
    color: #ec9177 !important;
    text-align: center;
    border: #ec9177 solid 1px;
    margin: 3em;
    padding: 2rem;
}
.create-account-button{
    margin-top: 2rem !important;
}