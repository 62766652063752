.searchbar-fields {
  padding: 0.3rem 0.6rem;
  background-color: rgba(215, 114, 86, .5);
}

.searchbar-fields div {
  white-space: nowrap;
  color: white;
}

.searchbar-buttons {
  text-align: center;
}

.searchbar-submit {
  text-align: center;
  align-items: flex-end;
}

.searchbar-submit a {
  border-radius: 4px;
  padding: .52rem 1.7rem;
  background-color: rgb(244, 172, 147);
  color: #ec9177 !important;
  font-weight: 600;
  transition: background-color .5s;
}

.searchbar-submit a:hover {
  cursor: pointer;
  background-color: #e7ded8;
}

.mbsc-mobiscroll .mbsc-btn-ic{
  padding: 0 !important;
}
.mbsc-mobiscroll.mbsc-btn {
  padding: .3875em .6875em !important;
}

@media screen and (min-width: 1025px) {
  .searchbar-fields {
    display: flex;
    padding: 0.3rem;
  }

  .searchbar-fields div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.3rem;
  }
}