.vendor-edit {
    margin-bottom: 1.5rem;
    margin-top: 1.2rem;
}
.vendor-edit-tab-button{
    margin: 0 !important;
}

.Toastify__toast--info {
    background: rgb(244, 172, 147) !important;
}

.Toastify__toast-container {
    color: #ec9177 !important;
}

.Toastify__close-button--info {
    color: #ec9177 !important;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #ec9177 !important;
}

.react-tabs__tab {
    padding:0 !important;
}

.vendorEdit__tab--selected {
    color: #fff !important;
    background: #ec9177 !important;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
}

.fileContainer {
    margin: 0rem auto;
    display: flex;
}

.mbsc-err-msg-big {
    color: red !important;
    font-size: large !important;
    text-align: center !important;
    font-weight: 900 !important;
    position: static !important;
}
.mbsc-msg-msg-big-center{
    align-self: center;
    color: #155724 !important;
    font-size: large !important;
    text-align: center !important;
    font-weight: 900 !important;
    position: static !important;
    padding-right: .5rem;
}
.vendor-picture-msg {
 display: flex;
 justify-content:center;
}

.vendorEdit-photo-example-center{
    font-weight: bolder;
    color: #b4715f;
}

.vendorEdit-photo-example{
    background: #acacac;
    width: 10rem;
    height: 6.67rem; 
    justify-content: center;
    align-items: center;
    display: flex;
}

.mbsc-msg-msg-big {
    color: #155724 !important;
    font-size: large !important;
    text-align: center !important;
    font-weight: 900 !important;
    position: static !important;
}

.smd-test{
    padding: .5em .575em .5em 1em;
    display: flex;
    justify-content: space-between;
}

.dolar-checked-big {
    color: rgb(255, 165, 0, 0.5);
    font-size: large;
}

.dolar-not-checked-big {
    color: rgb(0, 0, 0, 0.5);
}

.dolar-checked-big>i {
    padding-right: 0.21rem;
    font-size: 2rem;
}

.dolar-not-checked-big>i {
    padding-right: 0.21rem;
    font-size: 2rem;
}

.mbsc-err-msg {
    color: red !important;
    font-size: large !important;
    text-align: center !important;
    font-weight: 900 !important;
    position: static !important;
}

.fix-wrap {
    padding: 0.8em 0.8em 0 0.8em !important;
}

.fix-wrap-validation {
    padding-top: 0.8em !important;
}

.mbsc-mobiscroll.mbsc-btn.vendor-edit-tab-button-button{
    margin: 0 !important;
    padding: 0.25rem .6875rem !important;
}

.vendor-edit-tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: 1px solid #ec9177 !important;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    background: #f7f7f7;
    color: #ec9177;
}

.vendor-edit-tab-button{
    display: inline-block;
    position: relative;
    list-style: none;
    cursor: pointer;
    background: #f7f7f7;
    color: #ec9177;
    margin-left: 1rem;
}


.link-profile {
    color: #d8532e;
}