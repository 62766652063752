.carousel-item-height {
  width: auto;
  height: 15rem !important;
  max-height: 15rem !important;
  object-fit: contain;
}

.link {
  color: #ec9177;
}

.vendor-body {
  --lh: 1.4rem;
  line-height: var(--lh);
}

.vendor_alert {
  background-color: tomato !important;
}

.vendor-align-end {
  display: flex;
  justify-content: flex-end;
}

.vendor-align-end>button {
  margin-right: 0 !important;
}

.vendor-container {
  display: flex;
  border-bottom: 1px solid rgb(215, 113, 85);
  padding: 1.5rem .5rem 1rem .5rem;
}

.module {
  width: 100%;
  margin: 0 0 1em 0;
  padding-right: 0.3rem;
  overflow: hidden;
}

.module p {
  margin: 0;
  text-align: justify;
}

.overflow {
  --max-lines: 5;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
  /* space for ellipsis */
}

.overflow::before {
  position: absolute;
  content: "...";
  /*   inset-block-end: 0;
inset-inline-end: 0; */
  bottom: 0;
  right: 0;
}

.overflow::after {
  content: "";
  position: absolute;
  /*   inset-inline-end: 0; */
  right: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.vendor-body {
  padding-left: 1rem;
  cursor: pointer;
}

h5 {
  font-weight: 900;
  text-align: left;
  font-family: Poppins, sans-serif;
  color: #ec9177;
  margin-bottom: 0 !important;
}

h6 {
  padding: .1rem 0 .1rem 0;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 0.9rem;
  color: rgb(215, 113, 85, 0.7);
  margin-bottom: .3rem !important;
}

.dolar-checked {
  color: rgb(255, 165, 0, 0.5);
}

.dolar-not-checked {
  color: rgb(0, 0, 0, 0.5);
}

.dolar-checked>i {
  padding-right: 0.21rem;
}

.dolar-not-checked>i {
  padding-right: 0.21rem;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(215, 113, 85, 0.7)' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(215, 113, 85, 0.7)' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}