.notfound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('./Jupiter2LostInSpace.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    min-width: 800px;
    min-height: 800px;
    padding: 2rem;
  }
  