.agenda-divider {
  border-bottom: 1px solid #bcbcd1;
  color: #707070;
}

.form-loader {
  min-height: 1500px;
}

.generic-agenda-form-or {
  text-align: center;
  color: #008DA3;
  font-weight: bold;
  margin: 0 .8rem;
  border-top: 2px solid #bcbcd1;
}


button.generic-agenda-change-color {
  background-color: rgb(215, 113, 85, 0.7) !important;
}

.generic-agenda-fix-form-group {
  margin-bottom: .3rem;
  margin-top: .3rem;
}

.mbsc-cal-txt:after {
  content: '\274c';
  position: absolute;
  color: white !important;
  height: 1em;
  top: 0;
  bottom: 0;
  right: 0.6em;
}