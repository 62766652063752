.spinner-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: grey;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  @keyframes spinner-keyframes {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); } 
  }
  
  .spinner-circle{
    border: 1em solid #ccc;
    border-left-color: #fff;
    border-radius: 50%;
    height: 4em;
    width: 4em;
    animation: spinner-keyframes 1s linear infinite;
  }
