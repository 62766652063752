.mbsc-mobiscroll.mbsc-input{
  margin: 0.3em 0.8em;
}

.vendor-contact-title{
  padding: 0.5em 0.5em;
}

.vendor-contact-captcha{
  display: flex;
  justify-content: center;
}