.vendor-profile-main {
  margin-top: .4rem;
  padding-top: .9rem;
  border-top: 1px solid rgb(215, 113, 85, 0.5);
}
/* 
.carousel-item-height {
  width: auto;
  height: 30rem;
  max-height: 30rem;
  object-fit: contain;
} */

.vendor-profile-pad {
  margin-right: 1.5rem;
}

.vendor-profile-agenda {
  padding: 0 1rem;
  height: 600px;
}

.DayPicker {
  background-color: #F7F7F7;
}

.vendor-divider {
  border-top: 1px solid rgb(215, 113, 85, 0.5);
}

.vendor-profile-about-header {
  color: rgb(215, 113, 85, 0.5);
  margin-top: 1.5rem;
}

.vendor-profile-about {
  text-align: justify;
  text-justify: inter-word;
}
.vendor-description{
  font-size: .9rem;
}

.carousel-item-height-bigger{
  width: auto;
  height: 30rem !important;
  max-height: 30rem !important;
  object-fit: contain;
}