    /* border: 1px solid #ec9177; */

    .row {
      display: -ms-flexbox;
      /* IE10 */
      display: flex;
      -ms-flex-wrap: wrap;
      /* IE10 */
      flex-wrap: wrap;
      padding: 0 4px 2rem 4px;
    }

    .topright {
      position: absolute;
      top: -0.1rem;
      right: -0.1rem;
      height: 1.5rem;
      width:  1.5rem;
      color: white;
      background-color: red;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .7rem;
      cursor: pointer;
    }

    /* Create four equal columns that sits next to each other */
    .column {
      -ms-flex: 25%;
      /* IE10 */
      flex: 25%;
      max-width: 25%;
      padding: 0 .7rem;
      position: relative;
    }

    .custom-galery-select-images {
      margin-top: 0.8rem;
      margin-bottom: .8rem;
    }

    .btn-pad-left {
      margin-left: .8rem;
      ;
    }

    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    .column img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
    }

    /* Responsive layout - makes a two column-layout instead of four columns */
    @media screen and (max-width: 800px) {
      .column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
      }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
      .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
      }
    }