.contact-contain {
  object-fit: contain;
}

.faq-main{
  padding: 2rem 1rem;
  background-color: #f7f7ff;
}

.faq-any-question{
  font-weight: bold;
  font-size: 1.1rem;
}

.faq-li-question{
  font-weight: bold;
  font-size: 1.1rem;
  padding-bottom: 1.1rem;
}

.faq-li-answer{
  font-weight: 300;
  font-size: .9rem;
}

.faq-card-body{
  background-color: #f7f7ff;
  border-top: 1px solid rgb(215, 113, 85, 0.7);
}

.faq-card-header{
  padding: 0rem 1rem 1rem 0;
  color: rgb(215, 113, 85, 0.7);
}

.faq-buttons{
  padding-top: .5rem;
}

.faq-card {
  border: 0;
  border-radius: 0 !important;
}

.contact-img {
  padding: 20rem;
}